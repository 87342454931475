@import '../../../../scss/theme-bootstrap';

.content-block__inner-product {
  width: 100%;
  .content-block__single-product,
  .content-block__product-carousel {
    @include single-product;
    @include breakpoint($medium-up) {
      padding: 2.5rem 2.5rem 3rem;
    }
    .editorial-product-grid {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
    }
    .mpp-grid__wrapper {
      width: 100%;
      margin: 0;
    }
    .mpp-grid__item {
      display: flex;
      justify-content: center;
      height: auto;
      overflow: visible;
      margin-bottom: 0;
      width: 100%;
    }
  }
  .content-block__single-product {
    padding: 1rem 0 0;
    .content-block--split-width & {
      padding: 2rem 1.25rem 3rem;
    }
  }
  .content-block__product-carousel {
    padding: 0 1rem 3rem;
    .product-brief__image-container {
      @include breakpoint($medium-up) {
        width: 30%;
        order: 1;
      }
    }
    .product-brief__product-info-wrapper {
      @include breakpoint($medium-up) {
        width: 70%;
        order: 2;
      }
    }
  }
  .content-block__product-grid {
    @include breakpoint($medium-up) {
      display: flex;
      flex-wrap: wrap;
      .editorial-product-grid {
        display: flex;
        width: 50%;
        flex-wrap: wrap;
      }
      .mpp-grid__wrapper {
        width: 100%;
      }
      .mpp-grid__item {
        display: flex;
        justify-content: center;
        height: auto;
        overflow: visible;
        margin-bottom: 30px;
        width: 100%;
      }
      .product-brief {
        display: block;
        &.IE-min-height-processed {
          min-height: auto;
        }
      }
      .product-brief__flag,
      .product-brief__abstract {
        text-align: center;
      }
      .product__button {
        float: none;
      }
      .product-brief__image-container {
        max-width: 120px;
        margin: 0 auto;
      }
      .product-brief-shades {
        padding: 0;
      }
      .product-full__review-snippet {
        .p-w-r {
          .pr-snippet {
            padding: 0 0 8px;
          }
        }
      }
      .product-brief-shades__grid {
        display: none;
      }
      .product-brief__flag--subheader {
        margin: 0 auto 20px;
      }
      .product-brief__product-info-wrapper {
        width: 100%;
        display: block;
        flex: none;
      }
      .product-brief__bottom {
        margin: 0 auto;
      }
      .product-brief__abstract,
      .product-brief__price {
        margin-bottom: 0;
      }
      .product-brief__bottom-mobile {
        .product-brief__add-to-bag {
          .product__button--add-to-bag {
            width: auto;
            height: auto;
            line-height: 1;
          }
        }
      }
      .product-brief__abstract {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
      .product-brief__short-desc-link {
        display: block;
      }
    }
  }
  .product-brief-shades__hex {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
  }
  .product-brief__headline {
    width: 100%;
  }
  .product-brief__shades {
    min-height: 32px;
    min-width: auto;
    margin: 0 auto;
  }
  .product-brief-shades__personalization {
    display: none;
  }
  .hide-badges {
    .product-brief__flag {
      display: none;
    }
  }
  .carousel-controls {
    position: relative;
    display: flex;
    flex-direction: row;
    width: max-content;
    flex-wrap: wrap;
    padding: 0 0 20px 0;
    margin: 0 auto;
  }
  .slick-dots {
    bottom: 0;
    position: relative;
    width: max-content;
    margin: 0 auto;
    li {
      width: 17px;
      margin: 0;
      button:before {
        font-size: 30px;
      }
    }
  }
  .carousel-dots,
  .carousel-prev,
  .carousel-next {
    position: relative;
    width: max-content;
  }
  .carousel-prev {
    flex: 1;
    order: 1;
  }
  .carousel-dots {
    order: 2;
    flex: 1;
    top: 0;
  }
  .carousel-next {
    order: 3;
    flex: 1;
  }
  .slick-prev {
    min-width: 12px;
    min-height: 12px;
    #{$ldirection}: -24px;
    top: 14px;
    position: absolute;
  }
  .slick-next {
    min-width: 12px;
    min-height: 12px;
    #{$rdirection}: -26px;
    top: 14px;
    position: absolute;
    &:before {
      #{$rdirection}: auto;
      position: relative;
      height: auto;
    }
  }
  .slick-next,
  .slick-next:before,
  .slick-prev,
  .slick-prev:before {
    font-size: 20px;
    line-height: 1;
    color: $color-black;
  }
  .content-block-product {
    &--mobile {
      display: block;
      @include breakpoint($large-up) {
        display: none;
      }
    }
    &--pc {
      display: none;
      @include breakpoint($large-up) {
        display: flex;
      }
    }
  }
}
